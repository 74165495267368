export type BotoxJar = {
  count: number;
};

export type GameState = {
  target: number;
  done: number;
};

export const BotoxJarType = "BOTOXJARTYPE";

export type DropAreaType = {
  key: string;
  points: DropPointData[];
  initialImage: string;
  targetImage: string;
};

export type DropPointData = {
  key: string;
  target: number;
  accept: number;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

export const DropPointTarget = 4;

export type DragableItemPosition = { x: number; y: number };

export type DragableItemProps = {
  botoxCount: number;
  setBotoxCount: Function;
  resetGame: Function;
  dragableItemPosition: DragableItemPosition;
  setHintVisability: Function;
  hintVisbility: boolean;
};

export type DropPointProps = {
  onDrop: Function;
  target: number;
  accept: number;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  resetGameCount: number;
};

export type DropAreaProps = {
  target: number;
  points: DropPointData[];
  initialImage: string;
  targetImage: string;
  resetCounter: Function;
  resetGameCount: number;
};

export type UserData = { name: string; code: string };

export type UserPayload = {
  name: string;
  code: number;
  score?: number;
  remain_time?: number;
  date?: string;
};

import React from "react";

export default function Header() {
  return (
    <header className="app-header">
      <p>
        <span>DISPENSE </span> THE RIGHT AMOUNT AND
      </p>
      <p>
        <span>DROP </span> IT TO THE TREATABLE AREA
      </p>
    </header>
  );
}

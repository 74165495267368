import React, { useEffect, useState } from "react";
import { BotoxJarType, DropAreaProps } from "../utils/types";
import DropPoint from "./DropPoint";
import { useDrop } from "react-dnd";
import WrongAudio from "../assets/sounds/wrong.mp3";
import { ACTIONS, useScore } from "../utils/constants";
export default function DropArea({
  target,
  points,
  initialImage,
  targetImage,
  resetCounter,
  resetGameCount,
}: DropAreaProps) {
  const { dispatch } = useScore();
  const [count, setCount] = useState<number>(0);
  const [, drop] = useDrop(() => ({
    accept: BotoxJarType,
    drop: () => {
      new Audio(WrongAudio).play();
      dispatch({ type: ACTIONS.DECREAMEANT_SCORE, payload: 1 });
    },
  }));

  const updateAreaCounter = (addedAmount: number) => {
    setCount((count: number) => addedAmount + count);
    dispatch({ type: ACTIONS.INCREAMEANT_CHECKED, payload: addedAmount });
  };
  useEffect(() => {
    if (resetGameCount > 0) {
      setCount((_) => 0);
    }
  }, [resetGameCount]);
  return (
    <section className="drop-area">
      <span className="droppable-layer" ref={drop}></span>
      <img
        draggable={false}
        src={initialImage}
        className={`initial ${target === count ? "hide" : ""}`}
      />
      <img draggable={false} src={targetImage} className={`target `} />
      {points.map((point) => (
        <DropPoint
          resetGameCount={resetGameCount}
          {...point}
          key={point.key}
          onDrop={(addedAmount: number) => {
            updateAreaCounter(addedAmount);
            resetCounter();
          }}
        />
      ))}
    </section>
  );
}

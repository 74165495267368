import { useEffect, useMemo, useReducer, useState } from "react";
import {
  DropAreaType,
  DropPointTarget,
  DragableItemPosition,
} from "../utils/types";
import { ACTIONS, DropsArea, TOTAL_TIME, useScore } from "../utils/constants";
import DragableItem from "./DragableItem";
import DropArea from "./DropArea";
import HintImage from "../assets/images/hint-image.jpg";
import { Navigate, useNavigate } from "react-router-dom";
import WrongAudio from "../assets/sounds/wrong.mp3";
import { updateUserData } from "../utils/firebase-service";

export default function BotoxInjectionGame() {
  const navigate = useNavigate();
  const [hintVisbility, setHintVisability] = useState<boolean>(false);
  const [areas, setAreas] = useState<DropAreaType[]>(DropsArea);
  const [botoxCount, setBotoxCount] = useState<number>(0);
  const [time, setTime] = useState<number>(TOTAL_TIME);
  const [resetGameCount, setResetGameCount] = useState<number>(0);
  const [dragableItemPosition, setDragableItemPosition] =
    useState<DragableItemPosition>({
      x: 0,
      y: 0,
    });
  const { dispatch, score, code, gameChecked, gameTarget } = useScore();

  const resetCounter = () => {
    setBotoxCount((_) => 0);
  };

  const goToResult = async () => {
    await updateUserData({ code: Number(code), score, remain_time: time });
    navigate("/result", { replace: true });
  };

  const resetGame = () => {
    resetCounter();
    dispatch({ type: ACTIONS.RESET });
    setResetGameCount((prevCount: number) => prevCount + 1);
    setTime((time: number) => TOTAL_TIME);
  };

  const timeFormat = useMemo(() => {
    const completerdSeconds = parseInt(time / 60 + "");
    const fractions = parseInt((time % 60) + "");
    return ` ${String(completerdSeconds).padStart(2, "0")}:${String(
      fractions
    ).padStart(2, "0")}`;
  }, [time]);

  useEffect(() => {
    const duration = 1000;
    const interval = setInterval(() => {
      setTime((time: number) => {
        if (time > 0) return time - 1;
        else {
          return time;
        }
      });
    }, duration);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if ((gameChecked > 0 && gameChecked === gameTarget) || time <= 0) {
      if (time <= 0) {
        new Audio(WrongAudio).play();
      }
      goToResult();
    }
  }, [gameChecked, time]);

  // if (!code) {
  //   return <Navigate to={"/"} />;
  // }
  return (
    <section
      className="game-container"
      onDrag={(e) => {
        setDragableItemPosition((_) => ({ x: e.clientX, y: e.clientY }));
      }}
      onDragEnd={() => {
        setDragableItemPosition({ x: 0, y: 0 });
      }}
      onTouchMove={(e: any) => {
        const touch = e.touches[0];
        setDragableItemPosition((_) => ({
          x: touch.clientX,
          y: touch.clientY,
        }));
      }}
    >
      <article className="droppable-areas-container">
        {areas.map((area: DropAreaType) => (
          <DropArea
            key={area.key}
            {...{
              points: area.points,
              target: area.points.length * DropPointTarget,
              initialImage: area.initialImage,
              targetImage: area.targetImage,
              resetCounter,
              resetGameCount,
            }}
          />
        ))}
        <img
          src={HintImage}
          className={`hint-image ${hintVisbility ? "visible" : ""}`}
        />
        <p className="timer"> {timeFormat}</p>
      </article>
      <DragableItem
        {...{
          botoxCount,
          setBotoxCount,
          dragableItemPosition,
          hintVisbility,
          setHintVisability,
          resetGame,
        }}
      />
    </section>
  );
}

import { ChangeEvent, FormEvent, useState } from "react";
import ThreeCompletesMe from "../assets/images/3-completesMe.png";
import { useNavigate } from "react-router-dom";
import { UserData } from "../utils/types";
import { ACTIONS, useScore } from "../utils/constants";
import { addUserData, updateUserData } from "../utils/firebase-service";
export default function Home() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<UserData>({ name: "", code: "" });
  const submitAction = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log();
    const { name, code } = formData;
    if (name && code && Number(code) > 0) {
      dispatch({ type: ACTIONS.RESET });
      await addUserData({ name, code: Number(code) });
      dispatch({ type: ACTIONS.SET_USER_DATA, payload: { name, code } });

      navigate("/game", { replace: true });
    }
  };
  const updateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData: UserData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const { dispatch } = useScore();
  return (
    <section className="home-container">
      <div className="logo-container">
        <img src={ThreeCompletesMe} />
      </div>
      <div className="form-container">
        <form onSubmit={submitAction}>
          <div className="form-control">
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateFormData(e);
              }}
              value={formData.name}
              type="text"
              name="name"
              required
              autoComplete="off"
              placeholder="Enter Your Name"
            />
          </div>
          <div className="form-control">
            <input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateFormData(e);
              }}
              value={formData.code}
              type="number"
              name="code"
              required
              autoComplete="off"
              min={0}
              placeholder="Enter Your Code"
            />
          </div>
          <button>START NOW</button>
        </form>
      </div>
    </section>
  );
}

import React, { useEffect } from "react";
import { useDrag, useDragLayer } from "react-dnd";
import { BotoxJarType, DragableItemProps } from "../utils/types";
import BotoxJarBottle from "../assets/images/botox-bottel.png";
import { getEmptyImage } from "react-dnd-html5-backend";
import CustomDragLayer from "./DragLayer";
import { useScore } from "../utils/constants";

export default function DragableItem({
  botoxCount,
  setBotoxCount,
  resetGame,
  dragableItemPosition,
  setHintVisability,
  hintVisbility,
}: DragableItemProps) {
  const [{ isDragging }, drag, preview] = useDrag({
    type: BotoxJarType,
    item: { count: botoxCount },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const { score } = useScore();
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return (
    <div className={`draggable-item`}>
      <header>
        <p className={score > 0 ? "positive" : score < 0 ? "negative" : ""}>
          Score: <span>{score}</span>
        </p>
        <div className="actions">
          <button
            onClick={() => {
              resetGame();
            }}
          >
            <i className="fa-solid fa-rotate-right"></i>
          </button>
          <button
            onClick={() => {
              setHintVisability(!hintVisbility);
            }}
          >
            HINT
          </button>
        </div>
      </header>
      <CustomDragLayer dragableItemPosition={dragableItemPosition} />
      <article>
        <section className="bottles-count">
          <div>
            <img ref={drag} src={BotoxJarBottle} draggable="false" style={{}} />
            <p> {botoxCount} U</p>
          </div>
        </section>
        <section className="btns-container">
          <div className="button-wrapper">
            <button
              onClick={() => {
                if (botoxCount < 10) setBotoxCount(botoxCount + 1);
              }}
            >
              <i className="fa fa-add"></i>
            </button>
            <button
              onClick={() => {
                if (botoxCount > 0) setBotoxCount(botoxCount - 1);
              }}
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
        </section>
      </article>
    </div>
  );
}

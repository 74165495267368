import ThreeCompletesMe from "../assets/images/3-completesMe.png";
import BotoxLogo from "../assets/images/logo.png";
export default function Footer({
  showAllImages = true,
}: {
  showAllImages?: boolean;
}) {
  return (
    <footer>
      <div>
        {showAllImages && <img className="three" src={ThreeCompletesMe} />}
      </div>
      <div>
        <img src={BotoxLogo} className="logo" />
      </div>
    </footer>
  );
}

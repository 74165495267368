import React from "react";
import { ACTIONS, useScore } from "../utils/constants";
import { Navigate, useNavigate } from "react-router-dom";

export default function GameResult() {
  const navigate = useNavigate();
  const { dispatch, score, code } = useScore();

  if (!code) {
    return <Navigate to={"/"} />;
  }
  return (
    <section className="game-result-container">
      <h3>Way To Go Doctor!</h3>
      <h2>Your Score IS {score}</h2>
      <button
        onClick={() => {
          dispatch({ type: ACTIONS.RESET });
          navigate("/", { replace: true });
        }}
      >
        Start Over
      </button>
    </section>
  );
}

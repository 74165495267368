import BotoxJarBottle from "../assets/images/botox-bottel.png";
import { useDragLayer } from "react-dnd";
import { DragableItemPosition } from "../utils/types";
const CustomDragLayer = ({
  dragableItemPosition,
}: {
  dragableItemPosition: DragableItemPosition;
}) => {
  const { isDragging, currentOffset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  if (!isDragging) {
    return null;
  }
  return (
    <div
      className="drag-layer-container"
      style={{
        left: dragableItemPosition.x - 12.5,
        top: dragableItemPosition.y - 25,
        opacity: dragableItemPosition.x + dragableItemPosition.y === 0 ? 0 : 1,
      }}
    >
      <img src={BotoxJarBottle} />
    </div>
  );
};

export default CustomDragLayer;

import React, { useContext, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { BotoxJar, BotoxJarType, DropPointProps } from "../utils/types";
import SuccessAudio from "../assets/sounds/success.mp3";
import WrongAudio from "../assets/sounds/wrong.mp3";
import { ACTIONS, useScore } from "../utils/constants";

export default function DropPoint({
  onDrop,
  target,
  accept,
  top,
  left,
  bottom,
  right,
  resetGameCount,
}: DropPointProps) {
  const { dispatch: updateScore } = useScore();
  const [count, setCount] = useState<number>(0);

  const [collectedProps, drop] = useDrop(() => ({
    accept: BotoxJarType,
    drop: (droppedItem: BotoxJar) => {
      setCount((prevCount) => {
        if (droppedItem.count === accept && target > prevCount) {
          onDrop(droppedItem.count);
          new Audio(SuccessAudio).play();
          updateScore({ type: ACTIONS.INCREAMEANT_SCORE, payload: 5 });
          return prevCount + droppedItem.count;
        } else {
          new Audio(WrongAudio).play();
          updateScore({ type: ACTIONS.DECREAMEANT_SCORE, payload: 1 });
        }
        return prevCount;
      });
    },
  }));
  useEffect(() => {
    if (resetGameCount > 0) {
      setCount((_) => 0);
    }
  }, [resetGameCount]);
  return (
    <section
      className={`drop-target ${count === target ? "hide" : ""}`}
      ref={drop}
      style={{ top, left, right, bottom }}
    >
      <span></span>
    </section>
  );
}

import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import BotoxInjectionGame from "./components/BotoxInjectionGame";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import GameResult from "./components/GameResult";
import { ScoreContext, initialState, reducer } from "./utils/constants";
import { useReducer } from "react";
import ScoreBoard from "./components/ScoreBoard";
import ApprovalCode from "./components/ApprovalCode";
function App() {
  const [gameState, dispatch] = useReducer(reducer, initialState);
  return (
    <ScoreContext.Provider value={{ ...gameState, dispatch }}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Footer showAllImages={false} />
              </>
            }
          ></Route>
          <Route
            path="/game"
            element={
              <>
                <Header />
                <BotoxInjectionGame />
                <Footer />
              </>
            }
          ></Route>
          <Route
            path="/result"
            element={
              <>
                <GameResult />
                <Footer />
              </>
            }
          ></Route>
          <Route
            path="/score-board"
            element={
              <>
                <ScoreBoard />
                <Footer />
              </>
            }
          ></Route>
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
      <ApprovalCode />
    </ScoreContext.Provider>
  );
}

export default App;

import firebase from "firebase/app";
import "firebase/firestore";
import { UserPayload } from "./types";
import { TOTAL_TIME } from "./constants";

export const collectionName = "Botox-DND-Game";
const firebaseConfig = {
  apiKey: "AIzaSyBLSLO0o7HE-DBhnYQagzcgf7KjhGJdh5E",
  authDomain: "astrazeneca-respiratory.firebaseapp.com",
  projectId: "astrazeneca-respiratory",
  storageBucket: "astrazeneca-respiratory.appspot.com",
  messagingSenderId: "567579705965",
  appId: "1:567579705965:web:3c517e01e37e1ba0d5b564",
  measurementId: "G-1S6LBR5Q09",
};

let firebaseApp;
// Initialize Firebase
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} else {
  firebaseApp = firebase.app(); // if already initialized, use that one
}
// const analytics = getAnalytics(app);

export const db = firebase.firestore();

export default firebaseApp;

export const addUserData = async ({
  name,
  code,
  score = 0,
  remain_time = TOTAL_TIME,
  date = new Date().toISOString(),
}: UserPayload) => {
  return await db.collection(collectionName).doc(String(code)).set({
    name,
    code,
    score,
    remain_time,
    date,
  });
};

export const updateUserData = async ({
  code,
  score,
  remain_time,
}: {
  code: number;
  score: number;
  remain_time: number;
}) => {
  const doRef = await db.collection(collectionName).doc(String(code));
  const data = (
    await db.collection(collectionName).doc(String(code)).get()
  ).data();
  if (data) {
    doRef.update({ score, remain_time });
  }
};

const getAllData = async () => {
  const snapshot = await db.collection(collectionName).get();
  return snapshot;
};

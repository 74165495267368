import { DropAreaType, DropPointTarget } from "./types";
import FirstInitialImage from "../assets/images/1_before.png";
import FirstTargetImage from "../assets/images/1_after.png";
import SecondInitialImage from "../assets/images/2_before.png";
import SecondTargetImage from "../assets/images/2_after.png";
import ThirdInitialImage from "../assets/images/3_before.png";
import ThirdTargetImage from "../assets/images/3_after.png";
import ForthInitialImage from "../assets/images/4_before.png";
import ForthTargetImage from "../assets/images/4_after.png";
import { createContext, useContext } from "react";

export const DropsArea: DropAreaType[] = [
  {
    key: "1",
    points: [
      {
        key: "1",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "63.5%",
        left: "25.5%",
      },
      {
        key: "2",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "63.8%",
        left: "47.2%",
      },
      {
        key: "3",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "63.5%",
        left: "68.9%",
      },
      {
        key: "4",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "40%",
        left: "35.36%",
      },
      {
        key: "5",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "39.5%",
        left: "58.8%",
      },
    ],
    initialImage: FirstInitialImage,
    targetImage: FirstTargetImage,
  },
  {
    key: "2",
    points: [
      {
        key: "1",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "-4%",
        left: "30.25%",
      },
      {
        key: "2",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "11%",
        left: "39.35%",
      },
      {
        key: "3",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "11%",
        left: "54.5%",
      },
      {
        key: "4",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "70%",
        left: "47%",
      },
      {
        key: "5",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "-5%",
        left: "63.8%",
      },
    ],
    initialImage: SecondInitialImage,
    targetImage: SecondTargetImage,
  },
  {
    key: "3",
    points: [
      {
        key: "1",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "-4%",
        left: "33.5%",
      },
      {
        key: "2",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "15%",
        left: "28.5%",
      },
      {
        key: "3",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "34%",
        left: "33.5%",
      },
    ],
    initialImage: ThirdInitialImage,
    targetImage: ThirdTargetImage,
  },
  {
    key: "4",
    points: [
      {
        key: "1",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "-5%",
        right: "34%",
      },
      {
        key: "2",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "15%",
        right: "30%",
      },
      {
        key: "3",
        target: DropPointTarget,
        accept: DropPointTarget,
        top: "34%",
        right: "34%",
      },
    ],
    initialImage: ForthInitialImage,
    targetImage: ForthTargetImage,
  },
];

export type State = {
  gameTarget: number;
  gameChecked: number;
  score: number;
  code?: string;
  name: string;
  dispatch: any;
};

export const ACTIONS = {
  INCREAMEANT_SCORE: "INCREAMEANT_SCORE",
  DECREAMEANT_SCORE: "DECREAMEANT_SCORE",
  INCREAMEANT_CHECKED: "INCREAMEANT_CHECKED",
  SET_USER_DATA: "SET_USER_DATA",
  RESET: "RESET",
};

export const initialState: State = {
  gameTarget: DropsArea.map((area: DropAreaType) =>
    area.points.reduce((a, b) => a + b.target, 0)
  ).reduce((a, b) => a + b, 0),
  gameChecked: 0,
  score: 0,
  dispatch: undefined,
  name: "",
  code: undefined,
};

export const reducer = (
  state: State,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case ACTIONS.INCREAMEANT_SCORE:
      return { ...state, score: state.score + (action?.payload || 0) };
    case ACTIONS.DECREAMEANT_SCORE:
      return { ...state, score: state.score - (action?.payload || 0) };
    case ACTIONS.INCREAMEANT_CHECKED:
      return {
        ...state,
        gameChecked: state.gameChecked + (action?.payload || 0),
      };
    case ACTIONS.SET_USER_DATA:
      return {
        ...state,
        name: action.payload.name,
        code: action.payload.code,
      };

    case ACTIONS.RESET:
      return initialState;
    default:
      return state;
  }
};

export const ScoreContext = createContext(initialState);
export const useScore = () => useContext(ScoreContext);

export const TOTAL_TIME = 60 * 2; // seconds

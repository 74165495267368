import React, { useEffect, useState } from "react";
import { collectionName, db } from "../utils/firebase-service";
import { UserPayload } from "../utils/types";
import * as XLSX from "xlsx";
import { useSearchParams } from "react-router-dom";

const dateTimeOption: {} = {
  minute: "2-digit",
  hour: "2-digit",
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
};
export default function ScoreBoard() {
  const [searchParams, _] = useSearchParams();
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    db.collection(collectionName).onSnapshot((querySnapshot) => {
      setData((_) =>
        querySnapshot.docs
          .map((doc) => doc.data())
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          )
      );
    });
  }, []);

  const exportData = () => {
    const fileData = [
      ["Code", "Name", "Score", "Remain Time", "Date"],
      ...data.map((record: UserPayload) => [
        record.code,
        record.name,
        record.score,
        `${record.remain_time} s`,
        new Date(record.date!).toLocaleString("en-US", dateTimeOption),
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(fileData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "game-data.xlsx");
  };
  return (
    <section className="score-board-container">
      <h3>Score Board</h3>
      {searchParams.get("export") &&
        Boolean(searchParams.get("export") === "true") && (
          <button
            onClick={() => {
              exportData();
            }}
          >
            <i className="fa fa-download"></i>
          </button>
        )}
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Score</th>
            <th>Remain Time</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((record: any) => (
            <tr key={record.code} className="record">
              <td>{record.code}</td>
              <td>{record.name}</td>
              <td>{record.score}</td>
              <td>{`${record.remain_time} s`}</td>
              <td>
                {new Date(record.date).toLocaleString("en-US", dateTimeOption)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
